<template>
  <div class="content-wrapper site-content-wrapper clearfix">
    <pre>
    <center>用户隐私权保护声明</center>

“数独”注重保护用户个人信息及个人隐私。本隐私权保护声明解释了用户个人信息收集和保护的有关内容。

一、用户信息的收集

    用户知悉并同意，为便于向用户提供更好的服务，“数独”将在用户自愿选择服务或者提供信息的情况下收集用户的个人信息，并将这些信息进行整合。在用户使用“数独”服务时，服务器会自动记录一些信息，包括但不限于URL、IP地址、浏览器类型、使用语言、访问日期和时间等。“数独”同意善意使用收集的信息，且采取各项措施保证信息安全。

二、用户信息的保护
    “数独”不会公开或透露用户保存在“数独”服务器中的非公开内容，除非“数独”在诚信的基础上认为透露这些信息在以下几种情况是必要的：

        1. 事先获得用户明确授权的； 

        2. 用户自行向第三方共享、转让、公开的； 

        3. 与国家安全、国防安全、公共安全、公共利益直接相关的；

        4. 遵守有关法律规定，包括在国家有关机关查询时，提供用户的信息、用户在“数独”应用中上传的信息内容及其发布时间、互联网地址等；

        5. 在法律法规允许的范围内，为维护“数独”其他用户、公司及其关联公司、控制公司的生命、财产等合法权益或服务的安全稳定运行所必需的；

        6. 公司为维护合法权益而向用户提起诉讼或仲裁的

        7. 法律法规规定的或“数独”认为必要的其他情况。 

三、未成年人使用条款
    若用户是未满18周岁的未成年人，应在监护人监护、指导下阅读本协议和使用“数独”产品及相关服务。监护人已知悉，如未成年人违反法律法规的，则由监护人依照法律规定承担因此而导致的一切后果。
    
    “数独”重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待。

四、隐私权保护条款的变更

    “数独”保留解释及不定时更新本隐私权保护声明的权利。


如用户对本条款内容有任何疑问，请发送邮件至我们的客服邮箱：（284662271@qq.com）
  </pre
    >
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>
